exports.components = {
  "component---src-components-post-template-tsx": () => import("./../../../src/components/PostTemplate.tsx" /* webpackChunkName: "component---src-components-post-template-tsx" */),
  "component---src-components-province-page-template-tsx": () => import("./../../../src/components/Province/PageTemplate.tsx" /* webpackChunkName: "component---src-components-province-page-template-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-about-esdc-tsx": () => import("./../../../src/pages/about-esdc.tsx" /* webpackChunkName: "component---src-pages-about-esdc-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-agri-food-pilot-tsx": () => import("./../../../src/pages/agri-food-pilot.tsx" /* webpackChunkName: "component---src-pages-agri-food-pilot-tsx" */),
  "component---src-pages-appointment-tsx": () => import("./../../../src/pages/appointment.tsx" /* webpackChunkName: "component---src-pages-appointment-tsx" */),
  "component---src-pages-atlantic-immigration-pilot-tsx": () => import("./../../../src/pages/atlantic-immigration-pilot.tsx" /* webpackChunkName: "component---src-pages-atlantic-immigration-pilot-tsx" */),
  "component---src-pages-bc-pnp-calculator-tsx": () => import("./../../../src/pages/bc-pnp-calculator.tsx" /* webpackChunkName: "component---src-pages-bc-pnp-calculator-tsx" */),
  "component---src-pages-blogs-tsx": () => import("./../../../src/pages/blogs.tsx" /* webpackChunkName: "component---src-pages-blogs-tsx" */),
  "component---src-pages-bring-family-to-canada-tsx": () => import("./../../../src/pages/bring-family-to-canada.tsx" /* webpackChunkName: "component---src-pages-bring-family-to-canada-tsx" */),
  "component---src-pages-canadian-experience-class-tsx": () => import("./../../../src/pages/canadian-experience-class.tsx" /* webpackChunkName: "component---src-pages-canadian-experience-class-tsx" */),
  "component---src-pages-caregiver-program-tsx": () => import("./../../../src/pages/caregiver-program.tsx" /* webpackChunkName: "component---src-pages-caregiver-program-tsx" */),
  "component---src-pages-citizenship-tsx": () => import("./../../../src/pages/citizenship.tsx" /* webpackChunkName: "component---src-pages-citizenship-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-crs-calculator-tsx": () => import("./../../../src/pages/crs-calculator.tsx" /* webpackChunkName: "component---src-pages-crs-calculator-tsx" */),
  "component---src-pages-crs-tsx": () => import("./../../../src/pages/crs.tsx" /* webpackChunkName: "component---src-pages-crs-tsx" */),
  "component---src-pages-employer-compliance-tsx": () => import("./../../../src/pages/employer-compliance.tsx" /* webpackChunkName: "component---src-pages-employer-compliance-tsx" */),
  "component---src-pages-express-entry-draws-tsx": () => import("./../../../src/pages/express-entry-draws.tsx" /* webpackChunkName: "component---src-pages-express-entry-draws-tsx" */),
  "component---src-pages-express-entry-tsx": () => import("./../../../src/pages/express-entry.tsx" /* webpackChunkName: "component---src-pages-express-entry-tsx" */),
  "component---src-pages-family-sponsorship-tsx": () => import("./../../../src/pages/family-sponsorship.tsx" /* webpackChunkName: "component---src-pages-family-sponsorship-tsx" */),
  "component---src-pages-federal-skilled-trades-program-tsx": () => import("./../../../src/pages/federal-skilled-trades-program.tsx" /* webpackChunkName: "component---src-pages-federal-skilled-trades-program-tsx" */),
  "component---src-pages-federal-skilled-worker-program-tsx": () => import("./../../../src/pages/federal-skilled-worker-program.tsx" /* webpackChunkName: "component---src-pages-federal-skilled-worker-program-tsx" */),
  "component---src-pages-free-assessment-tsx": () => import("./../../../src/pages/free-assessment.tsx" /* webpackChunkName: "component---src-pages-free-assessment-tsx" */),
  "component---src-pages-immigration-assessment-tsx": () => import("./../../../src/pages/immigration-assessment.tsx" /* webpackChunkName: "component---src-pages-immigration-assessment-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-join-family-tsx": () => import("./../../../src/pages/join-family.tsx" /* webpackChunkName: "component---src-pages-join-family-tsx" */),
  "component---src-pages-lmia-based-work-permit-tsx": () => import("./../../../src/pages/lmia-based-work-permit.tsx" /* webpackChunkName: "component---src-pages-lmia-based-work-permit-tsx" */),
  "component---src-pages-lmia-exempt-work-permit-tsx": () => import("./../../../src/pages/lmia-exempt-work-permit.tsx" /* webpackChunkName: "component---src-pages-lmia-exempt-work-permit-tsx" */),
  "component---src-pages-pg-work-permit-tsx": () => import("./../../../src/pages/pg-work-permit.tsx" /* webpackChunkName: "component---src-pages-pg-work-permit-tsx" */),
  "component---src-pages-pnp-tsx": () => import("./../../../src/pages/pnp.tsx" /* webpackChunkName: "component---src-pages-pnp-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-renew-study-permit-tsx": () => import("./../../../src/pages/renew-study-permit.tsx" /* webpackChunkName: "component---src-pages-renew-study-permit-tsx" */),
  "component---src-pages-rural-and-northern-immigration-pilot-tsx": () => import("./../../../src/pages/rural-and-northern-immigration-pilot.tsx" /* webpackChunkName: "component---src-pages-rural-and-northern-immigration-pilot-tsx" */),
  "component---src-pages-sirs-tsx": () => import("./../../../src/pages/sirs.tsx" /* webpackChunkName: "component---src-pages-sirs-tsx" */),
  "component---src-pages-student-direct-stream-tsx": () => import("./../../../src/pages/student-direct-stream.tsx" /* webpackChunkName: "component---src-pages-student-direct-stream-tsx" */),
  "component---src-pages-study-assessment-tsx": () => import("./../../../src/pages/study-assessment.tsx" /* webpackChunkName: "component---src-pages-study-assessment-tsx" */),
  "component---src-pages-study-in-canada-tsx": () => import("./../../../src/pages/study-in-canada.tsx" /* webpackChunkName: "component---src-pages-study-in-canada-tsx" */),
  "component---src-pages-study-pathways-to-pr-tsx": () => import("./../../../src/pages/study-pathways-to-pr.tsx" /* webpackChunkName: "component---src-pages-study-pathways-to-pr-tsx" */),
  "component---src-pages-study-without-permit-tsx": () => import("./../../../src/pages/study-without-permit.tsx" /* webpackChunkName: "component---src-pages-study-without-permit-tsx" */),
  "component---src-pages-tfwp-and-imp-tsx": () => import("./../../../src/pages/tfwp-and-imp.tsx" /* webpackChunkName: "component---src-pages-tfwp-and-imp-tsx" */),
  "component---src-pages-work-permit-process-tsx": () => import("./../../../src/pages/work-permit-process.tsx" /* webpackChunkName: "component---src-pages-work-permit-process-tsx" */),
  "component---src-pages-work-without-permit-tsx": () => import("./../../../src/pages/work-without-permit.tsx" /* webpackChunkName: "component---src-pages-work-without-permit-tsx" */),
  "component---src-pages-working-while-studying-tsx": () => import("./../../../src/pages/working-while-studying.tsx" /* webpackChunkName: "component---src-pages-working-while-studying-tsx" */)
}

